import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import {
  fullWidth,
  ArtDirectedImage,
  DivToMakeAbsoluteImageLookRelative,
} from "components/Design/ArtDirectedImage";
import SecondaryButton from "components/Design/Button/SecondaryButton";
import IconLinkButton from "components/Design/Button/IconLinkButton";
import PageSection from "components/Design/PageSection/PageSection";
import { Container } from "components/Design/Grid/Grid";

const Dots = ({ count, highlight, onIndicatorClick }) => {
  return (
    <ul className="space-x-12 absolute inset-x-0 bottom-12 z-1 flex items-center justify-center">
      {Array.from(Array(count)).map((_, index) => {
        return (
          <li
            className={cx(
              "inline-block rounded-full h-6 w-6 opacity-50 bg-white cursor-pointer",
              {
                "opacity-100": highlight === index,
              }
            )}
            onClick={() => {
              onIndicatorClick(index);
            }}></li>
        );
      })}
    </ul>
  );
};

const Card = ({ image }) => {
  return <ArtDirectedImage image={image} className="rounded-sm" />;
};

const Track = ({ device, children }) => {
  return <div className="for-debugging-track">{children}</div>;
};

const Viewport = ({ device, children }) => {
  return <div className="relative for-debugging-viewport">{children}</div>;
};

const variants = {
  enter: {
    zIndex: 1,
    opacity: 0,
  },
  animate: {
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
};

const HotelCarousel = ({ cards }) => {
  const [index, setIndex] = useState(0);

  const goNext = () => {
    setIndex((index + 1) % cards.length);
  };

  const goPrev = () => {
    const newIndex = index === 0 ? cards.length - 1 : index - 1;
    setIndex(newIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(() => goNext(), 3000);

    return () => clearTimeout(timeout);
  }, [index]);

  const onIndicatorClick = (slideIndex) => {
    setIndex(slideIndex);
  };

  const panAnimate = (_, { offset }) => {
    if (offset.x > 0) {
      goPrev();
    } else {
      goNext();
    }
  };

  return (
    <Viewport>
      <Track>
        <AnimatePresence initial={false}>
          <motion.div
            className="absolute w-full"
            key={cards[index].id}
            variants={variants}
            initial="enter"
            animate="animate"
            exit="exit"
            transition={{
              opacity: { duration: 1 },
              type: "tween",
              ease: "easeIn",
            }}
            onPan={panAnimate}>
            <Card image={cards[index]} />
          </motion.div>
        </AnimatePresence>
      </Track>
      <Dots
        count={cards.length}
        highlight={index}
        onIndicatorClick={onIndicatorClick}
      />
      <DivToMakeAbsoluteImageLookRelative image={cards[index]} />
    </Viewport>
  );
};

const HotelCard = ({ hotel, card, t }) => {
  return (
    <div className="col-span-4 flex flex-col justify-start font-mulish text-gray-text w-full lg:w-320">
      <HotelCarousel cards={card.images} />
      <div className="card-content relative flex flex-col">
        <Link to={card.link}>
          <h4 className="font-cormorant lg:min-h-100 text-h4 font-semibold py-12 lg:pt-20">
            <span className="hidden lg:block m-h4">{card.title}</span>
          </h4>
        </Link>
        <div className="lg:hidden text-center">
          <GatsbyImage
            image={fullWidth(hotel.logo)}
            className="w-90 mb-12 mx-auto"
          />
        </div>
        <p className="mb-10 font-cormorant text-16-24">{card.description}</p>
      </div>
      <div className="card-content text-12-22 tracking-16 mt-auto">
        <Link to={card.link} className="lg:hidden mt-12">
          <SecondaryButton label={t("Explore Hotel")} block />
        </Link>
        <div className="hidden lg:block mb-20">
          <IconLinkButton btnText={t("Explore Hotel")} href={card.link} />
        </div>
        <div className="hidden lg:block border-b border-gold opacity-50" />
      </div>
    </div>
  );
};

export const HotelsSection = ({ src, hotels, background }) => {
  const { t } = useTranslation("homepage");

  // There needs to be a better way
  const hotelCards = [];
  src.cards
    .sort((first, second) => first.sequence - second.sequence)
    .map((card) =>
      hotels.forEach((hotel) => {
        // Temporarily we are using category_en to get hotel id
        return hotel.id === card.category_en && hotelCards.push([hotel, card]);
      })
    );

  // Todo: Remove background and pass it as prop to PageSection
  return (
    <div className={cx(background)}>
      <PageSection
        className="py-40"
        title={
          <span className="block text-m-h2 text-left lg:text-center">
            {src.title}
          </span>
        }>
        <Container>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-8 lg:gap-x-20">
            {hotelCards.map(([hotel, card]) => (
              <HotelCard hotel={hotel} card={card} t={t} />
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  );
};
