import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AYANA_BALI } from "store/constants/hotel";
import Layout from "components/Layout/Layout";
import PageContext from "contexts/PageContext";
import { BannerCarousel } from "components/BannerCarousel.jsx";
import SecondaryButton from "components/Design/Button/SecondaryButton";
import { HotelsSection } from "components/HotelsSection";
import { LandingMap } from "components/LandingMap";
import { HighlightsCarousel } from "components/Design/Carousel/HighlightsCarousel";
import { HighlightSection } from "../../components/HighlightSection/HighlightSection";
import { AYANA_BALI_WHATSAPP_LINK } from "store/constants/socmedLink";
import { transformObject } from "utils/transformv4";
import { contactUsCard, offers, sortBlocks } from "utils/renderBlocks";
import { Container } from "components/Design/Grid/Grid";
import { CardContactUs } from "components/Design/Card/CardContactUs";
import { Highlights } from "components/Highlights";
import { HighlightOffer } from "components/Design/Card/Offer";
import Icon from "components/CommonIcon/Icon";

const BaliLanding = ({ data }) => {
  const { t } = useTranslation(["homepage", "offersLanding"]);
  const [showBanner, setShowBanner] = useState(false);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const pageData = transformObject(cms);

  const {
    pagesByLocale: [
      {
        seo_tags,
        sections,
        banners: [banner],
        localizations: [localization],
        blocks: blocksFromPage,
      },
    ],
    sections: [destinations],
    hotelsByLocale: [hotel],
    supportDrawer: [supportDrawer],
    hotels,
    hotelPropertyBali,
  } = pageData;

  const blocks = blocksFromPage || localization.blocks;

  const introSection = sections.find(
    (section) => section.name === "Bali_Landing_Introduction"
  );

  const hotelSection = sections.find(
    (section) => section.name === "Bali_Landing_HotelSelection"
  );

  const baliUSPSection = sections.find(
    (section) => section.name === "Bali_Landing_BaliUSP"
  );

  const diningSection = sections.find(
    (section) => section.name === "Bali_Landing_Dining"
  );

  const experienceSection = sections.find(
    (section) => section.name === "Bali_Landing_Experience"
  );

  const spaSection = sections.find(
    (section) => section.name === "Bali_Landing_Spa"
  );

  const landingMap = sections.find(
    (section) => section.name === "Bali_Landing_Map"
  );

  const supportDrawerData = {
    ...supportDrawer,
    hotels: hotelPropertyBali,
    hotel: hotel,
  };

  const activities = hotel.activities;

  const offersBlock = offers(blocks);
  const contactUsBlock = contactUsCard(blocks);

  return (
    <PageContext.Provider
      value={{ showBannerPadding: true, location: AYANA_BALI }}>
      <Layout
        whatsAppLink={AYANA_BALI_WHATSAPP_LINK}
        destination={t("Bali")}
        banner={
          <BannerCarousel
            images={banner.images}
            subHeading={banner.title}
            title={banner.subtitle}
            setShowBanner={setShowBanner}
            homepageHeading
            fade
          />
        }
        seoTitle={t("Bali Main Landing")}
        seoTags={seo_tags}
        destinationCards={destinations.images}
        showBanner={showBanner}
        navigations={navigations}
        hotel={{ ...hotel, ...hotel.destinations[0] }}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Bali")}
        supportDrawer={supportDrawerData}
        hotelPropertyBali={hotelPropertyBali}
        homepage>
        <div className="bg-gradient-hospitality">
          <h3 className="text-m-h2 lg:text-h3 text-gray-text max-w-480 text-center mx-auto pt-60 lg:pb-20 px-20 lg:px-0">
            {introSection.description}
          </h3>
        </div>
        <HotelsSection
          background="bg-grey-seashell"
          src={hotelSection}
          hotels={hotels}
        />
        <LandingMap
          backgroundColor="bg-grey-seashell"
          image={landingMap.images[0]}
          altTitle={landingMap.title}
        />
        <HighlightSection
          background="bg-gradient-rooms lg:bg-grey-seashell"
          src={baliUSPSection}
        />
        <HighlightsCarousel
          background="bg-gradient-hospitality lg:bg-gradient-rooms"
          content={diningSection}
          cta={
            <Link to="/bali/dining/">
              <div className="lg:w-320 mx-auto">
                <SecondaryButton block label={t("Explore Dining")} />
              </div>
            </Link>
          }
          parentAchorLink="/bali/dining/#"
          textLink={t("See Detail")}
        />
        <HighlightsCarousel
          background="bg-gradient-rooms lg:bg-gradient-hospitality"
          content={experienceSection}
          activities={activities}
          cta={
            <Link to="/bali/experience/">
              <div className="lg:w-320 mx-auto">
                <SecondaryButton block label={t("Explore Experiences")} />
              </div>
            </Link>
          }
          parentAchorLink="/bali/experience/#"
          textLink={t("See Detail")}
        />

        <HighlightsCarousel
          background="bg-gradient-hospitality lg:bg-gradient-rooms"
          content={spaSection}
          cta={
            <Link to="/bali/spa/">
              <div className="lg:w-320 mx-auto">
                <SecondaryButton block label={t("Explore Spa")} />
              </div>
            </Link>
          }
          textLink={t("See Detail")}
        />

        <Container>
          <div className="py-20 lg:py-40">
            <Highlights
              block={{
                ...sortBlocks(offersBlock),
                title: t("offersLanding:Special Offers"),
                cta_text: t("homepage:Explore All Offers"),
              }}
              icon={<Icon name="tag" />}>
              {sortBlocks(offersBlock).list.map(({ offer }) => (
                <HighlightOffer key={offer.id} offer={offer} />
              ))}
            </Highlights>
          </div>
        </Container>

        <CardContactUs
          description={t("homepage:contactUsText")}
          label={t("homepage:Contact Us")}
          url={contactUsBlock.support_url}
        />
      </Layout>
    </PageContext.Provider>
  );
};

export default BaliLanding;

export const query = graphql`
  query BaliLanding($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      hotels: hotels {
        data {
          id
          attributes {
            name
            logo {
              ...CmsUploadFileEntityResponse
            }
          }
        }
      }

      hotelsByLocale(language: $language, where: { id: 2 }) {
        data {
          id
          attributes {
            phone
            location
            name
            whatsapp_contact
            custom_whatsapp_message
            destinations {
              ...CmsDestinations
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            activities {
              ...CmsActivities_v4
            }
          }
        }
      }

      sections(filters: { name: { eq: "Bali_Footer_Destination" } }) {
        data {
          id
          attributes {
            title
            images(sort: "sequence") {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }

      supportDrawer: sections(
        filters: { name: { eq: "Bali_Support_Drawer" } }
      ) {
        data {
          id
          attributes {
            ...CmsSection_v4
            images {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }

      pagesByLocale(language: $language, where: { name: "Bali_Landing" }) {
        data {
          id
          attributes {
            ...CmsPageDynamicBlocks_v4
            localizations(filters: { locale: { eq: $language } }) {
              data {
                id
                attributes {
                  ...CmsPageDynamicBlocks_v4
                }
              }
            }
            ...CmsPageDynamicBlocks_v4
            ...CmsPageSeoTags_v4
            banners {
              ...CmsBannerFullWidth_v4
            }
            sections {
              data {
                id
                attributes {
                  ...CmsSection_v4
                  cards(sort: "sequence") {
                    ...CmsCardFullWidth_v4
                  }
                  offers {
                    data {
                      id
                      attributes {
                        ...CmsOfferFullWidth_v4
                        slug
                        hotels {
                          data {
                            id
                            attributes {
                              title
                            }
                          }
                        }
                      }
                    }
                  }
                  images {
                    ...CmsImageFullWidth_v4
                  }
                }
              }
            }
          }
        }
      }

      hotelPropertyBali: hotelsByLocale(
        language: $language
        where: { id: [1, 2, 3, 9] }
        sort: "id:DESC"
      ) {
        data {
          id
          attributes {
            name
            title
            logo {
              ...CmsUploadFileEntityResponse
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
          }
        }
      }
    }
  }
`;
