import React from "react";
import { ArtDirectedImage } from "components/Design/ArtDirectedImage";
import PageSection from "./Design/PageSection/PageSection";

export const LandingMap = ({ backgroundColor, image, altTitle }) => {
  return (
    <div className={`${backgroundColor} px-20`}>
      <PageSection>
        <div className="container max-w-screen-lg mx-auto">
          <ArtDirectedImage
            image={image}
            alt={altTitle}
            className="rounded-sm"
          />
        </div>
      </PageSection>
    </div>
  );
};
