import React from "react";
import cx from "classnames";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { slugify } from "utils/slugify";
import PageSection from "components/Design/PageSection/PageSection";
import { Container } from "components/Design/Grid/Grid";
import Icon from "components/CommonIcon/Icon";
import { ArtDirectedImage } from "components/Design/ArtDirectedImage";
import { BasicSlider } from "./BasicSlider";
import { SliderArrows } from "components/SliderArrows";

const ThumbnailSectionCard = ({ card, parentAnchorLink, ctaText, state }) => {
  const image = card;
  const title = card.title;
  const link = parentAnchorLink
    ? parentAnchorLink + slugify(card.title_en)
    : card.link;
  const locationState = state && {
    tabActive: `#${card.tabName}`,
    scrollToHash: true,
  };

  return (
    <Link
      to={link}
      state={locationState}
      className="relative block w-300 lg:w-320 shrink-0 z-1">
      <ArtDirectedImage
        image={image}
        alt={title}
        className="h-full w-full"
        imgClassName="rounded-sm"
      />
      <div className="absolute bottom-0 inset-x-0 px-12 pb-12 z-1 text-white bg-gradient-overlay">
        <span className="text-24-32 font-semibold">{title}</span>
        <span className="font-mulish font-semibold uppercase text-12-22 flex items-center tracking-16 mt-8">
          {ctaText}
          <span className="ml-5">
            <Icon
              name="right-arrow-thin"
              fontSize="text-12"
              color="text-white"
            />
          </span>
        </span>
      </div>
    </Link>
  );
};

export const HighlightsCarousel = ({
  content,
  textLink,
  cta,
  background,
  parentAchorLink,
  activities,
}) => {
  const cardsWithTab = content.cards.map((card) => {
    const tabName = activities
      ?.filter((activityCard) => card.title_en === activityCard.title_en)
      .map((activityCard) => activityCard?.activity_types[0]?.name)[0]
      ?.toLowerCase()
      ?.replace(/\s/g, "-");
    return { ...card, tabName: tabName };
  });

  const cards = activities ? cardsWithTab : content.cards;

  return (
    <PageSection
      className={cx("py-20 lg:py-20", background)}
      title={
        <span className="block text-m-h2 text-left lg:text-center">
          {content.title}
        </span>
      }
      description={content.description}>
      <div className="w-full">
        <Container>
          <BasicSlider
            cardsToShow={3}
            renderArrows={({ goNext, goPrev }) => (
              <SliderArrows goNext={goNext} goPrevious={goPrev} />
            )}>
            {cards.map((card, idx) => (
              <ThumbnailSectionCard
                id={idx}
                card={card}
                parentAnchorLink={parentAchorLink}
                state={Boolean(activities)}
                ctaText={textLink}
              />
            ))}
          </BasicSlider>
        </Container>
      </div>
      <div className="mt-24 px-20">{cta}</div>
    </PageSection>
  );
};

HighlightsCarousel.defaultProps = {
  bg: "bg-gradient-rooms",
  parentPath: "/labuan-bajo/komodo",
};
